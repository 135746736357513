import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";

import * as taskService from "../../../util/taskService";
import DeleteSkillDialog from "./DeleteSkillDialog";
import AddSkillDialog from "./AddSkillDialog";
import "../../../css/display/Management.css";

export default function ManagementDisplay() {
  const [obj, setObject] = useState([]);
  const [selectedSkill, setSelectedSkill] = useState({});
  const [showDeleteButton, setShowDeleteButton] = useState(false);
  const [deleteSkillDialogOpen, setDeleteSkillDialogOpen] = useState(false);
  const [addSkillDialogOpen, setAddSkillDialogOpen] = useState(false);

  const handleOpenDeleteSkillDialog = () => {
    setDeleteSkillDialogOpen(true);
  };

  const handleCloseDeleteSkillDialog = () => {
    setDeleteSkillDialogOpen(false);
  };

  const handleOpenAddSkillDialog = () => {
    setAddSkillDialogOpen(true);
  };

  const handleCloseAddSkillDialog = () => {
    setAddSkillDialogOpen(false);
  };

  const fetchSkills = async () => {
    try {
      const response = await taskService.getSkills();
      setObject(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const createSkill = async (skill) => {
    try {
      const result = await taskService.createSkill(skill);
      console.log("Skill created:", result);
      await fetchSkills();
      setAddSkillDialogOpen(false);
    } catch (error) {
      console.error("Error creating skill:", error);
    }
  };

  const handleDelete = async (id) => {
    try {
      const result = await taskService.deleteSkill(id);
      console.log("Skill deleted:", result);
      await fetchSkills();
      setShowDeleteButton(false);
      setDeleteSkillDialogOpen(false);
    } catch (error) {
      console.error("Error deleting skill:", error);
    }
  };

  useEffect(() => {
    fetchSkills();
  }, []);

  useEffect(() => {
    // Add a click event listener when the component mounts
    const handleClickOutside = (event) => {
      if (
        showDeleteButton &&
        !event.target.closest(".listElement") &&
        !event.target.closest(".deleteButton")
      ) {
        setShowDeleteButton(false);
      }
    };

    // Attach the event listener to the document
    document.addEventListener("click", handleClickOutside);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [showDeleteButton]);

  const AddButton = styled(Button)(({ theme }) => ({
    fontFamily: "'Montserrat', sans-serif",
    color: "rgb(60, 61, 61)",
    width: "20%",
    fontSize: "0.8rem",
    cursor: "pointer",
    border: "none",
    backgroundColor: "#aacbb7e5",
    fontWeight: "bold",
    marginRight: "0.5rem",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#78b79a",
    },
  }));

  const DeleteButton = styled(Button)(({ theme }) => ({
    fontFamily: "'Montserrat', sans-serif",
    color: "rgb(60, 61, 61)",
    width: "20%",
    fontSize: "0.8rem",
    cursor: "pointer",
    border: "none",
    backgroundColor: "#d59393e5",
    fontWeight: "bold",
    marginRight: "0.5rem",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#b77878",
    },
  }));

  return (
    <div className="managementDisplay">
      <DeleteSkillDialog
        open={deleteSkillDialogOpen}
        skillName={selectedSkill.name}
        onConfirm={() => handleDelete(selectedSkill.id)}
        onCancel={handleCloseDeleteSkillDialog}
      />
      <AddSkillDialog
        open={addSkillDialogOpen}
        onConfirm={createSkill}
        onCancel={handleCloseAddSkillDialog}
      />
      <h1>Kompetenzen</h1>
      <div className="listContainer">
        {obj.map((entry) => (
          <button
            key={entry.id}
            className="listElement"
            onClick={() => {
              setShowDeleteButton(true);
              setSelectedSkill(entry);
            }}
          >
            {entry.name}
          </button>
        ))}
      </div>
      <Stack
        sx={{
          marginRight: 2,
          justifyContent: "flex-end",
          alignItems: "center",
        }}
        direction="row"
        spacing={2}
      >
        {showDeleteButton && (
          <DeleteButton
            className="deleteButton"
            variant="contained"
            startIcon={<DeleteIcon />}
            onClick={handleOpenDeleteSkillDialog}
          >{`${selectedSkill.name} entfernen`}</DeleteButton>
        )}
        <AddButton
          variant="contained"
          startIcon={<AddIcon />}
          onClick={handleOpenAddSkillDialog}
        >
          {"Hinzufügen"}
        </AddButton>
      </Stack>

      <h1>Zertifikate</h1>
      <div className="listContainer">
        <button className="listElement">test</button>
      </div>
    </div>
  );
}
