import React, { useState } from "react";
import * as taskService from "../../../util/taskService";

export default function DataDisplay() {
  const [result, setResult] = useState("");
  const [obj, setObject] = useState({});

  const fetchClassification = async () => {
    try {
      //const response = await axios.get('/task/classification');
      const response = await taskService.getClassifications();
      setResult(JSON.stringify(response.data));
      setObject(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
      setResult("Error fetching data");
    }
  };

  const fetchPriority = async () => {
    try {
      const response = await taskService.getPriorities();
      setResult(JSON.stringify(response.data));
      setObject(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
      setResult("Error fetching data");
    }
  };

  const fetchTicket = async () => {
    try {
      const response = await taskService.getTickets({ type: "customer" });
      setResult(JSON.stringify(response.data));
      setObject(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
      setResult("Error fetching data");
    }
  };

  const fetchPhase = async () => {
    try {
      const response = await taskService.getPhases();
      setResult(JSON.stringify(response.data));
      setObject(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
      setResult("Error fetching data");
    }
  };

  const fetchProject = async () => {
    try {
      const response = await taskService.getProjects();
      setResult(JSON.stringify(response.data));
      setObject(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
      setResult("Error fetching data");
    }
  };

  const fetchSkills = async () => {
    try {
      const response = await taskService.getSkills();
      setResult(JSON.stringify(response.data));
      setObject(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
      setResult("Error fetching data");
    }
  };

  const fetchMilestone = async () => {
    try {
      const response = await taskService.getMilestones();
      setResult(JSON.stringify(response.data));
      setObject(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
      setResult("Error fetching data");
    }
  };

  const fetchComment = async () => {
    try {
      const response = await taskService.getComments();
      setResult(JSON.stringify(response.data));
      setObject(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
      setResult("Error fetching data");
    }
  };

  const fetchAmendment = async () => {
    try {
      const response = await taskService.getAmendment();
      setResult(JSON.stringify(response.data));
      setObject(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
      setResult("Error fetching data");
    }
  };

  return (
    <div>
      <br />
      <button onClick={fetchClassification}>ApiTaskClassification</button>
      <button onClick={fetchPriority}>ApiTaskPriority</button>
      <button onClick={fetchTicket}>ApiTaskTicket</button>
      <br />
      <br />
      <button onClick={fetchPhase}>ApiTaskPhase</button>
      <button onClick={fetchProject}>ApiTaskProject</button>
      <button onClick={fetchSkills}>ApiTaskSkills</button>
      <br />
      <br />
      <button onClick={fetchMilestone}>ApiTaskMilestone</button>
      <button onClick={fetchComment}>ApiTaskComment</button>
      <button onClick={fetchAmendment}>ApiTaskAmendment</button>
      <br />

      <br />
      <textarea
        value={result}
        rows={10}
        cols={50}
        readOnly
        style={{ marginTop: "10px" }}
      />
      <textarea
        value={JSON.stringify(obj[0])}
        rows={10}
        cols={50}
        readOnly
        style={{ marginTop: "10px" }}
      />
    </div>
  );
}
