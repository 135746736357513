import axios from "axios";

const taskApi = axios.create({
  baseURL: process.env.REACT_APP_API_URL || "/",
  headers: {
    "Content-Type": "application/json",
  },
});

console.log("envUrl " + process.env.REACT_APP_API_URL);
console.log("environment: " + process.env.NODE_ENV);

export const getTickets = async (params) => {
  console.log(taskApi.defaults.baseURL);
  const response = await taskApi.get("/task/ticket", { params });
  return response;
};
export const getClassifications = async () => {
  console.log(taskApi.defaults.baseURL);
  const response = await taskApi.get("/task/classification");
  return response;
};
export const getPriorities = async () => {
  const response = await taskApi.get("/task/priority");
  return response;
};
export const getPhases = async () => {
  const response = await taskApi.get("/task/phase");
  return response;
};
export const getProjects = async () => {
  const response = await taskApi.get("/task/project");
  return response;
};

export const getSkills = async () => {
  console.log(taskApi.defaults.baseURL);
  const response = await taskApi.get("/task/skill");
  return response;
};
export const createSkill = async (params) => {
  try {
    const { data } = await taskApi.post("/task/skill", params);
    return data;
  } catch (error) {
    console.error("Error creating skill:", error);
    throw error;
  }
};
export const deleteSkill = async (id) => {
  try {
    const { data } = await taskApi.delete(`/task/skill/${id}`);
    return data;
  } catch (error) {
    console.error("Error deleting skill:", error);
    throw error;
  }
};

export const getMilestones = async () => {
  const response = await taskApi.get("/task/milestone");
  return response;
};
export const getComments = async () => {
  const response = await taskApi.get("/task/comment");
  return response;
};
export const getAmendment = async () => {
  const response = await taskApi.get("/task/amendment");
  return response;
};
