import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

export default function DeleteSkillDialog({
  open,
  skillName,
  onConfirm,
  onCancel,
}) {
  return (
    <Dialog
      open={open}
      onClose={onCancel}
      aria-labelledby="Skill Deletion"
      aria-describedby="Delete specific skill"
    >
      <DialogTitle id="Skill Deletion">Entfernen einer Kompetenz</DialogTitle>
      <DialogContent>
        <DialogContentText id="Delete specific skill">
          Möchten Sie folgende Kompetenz löschen?
          <Typography component="span" sx={{ fontWeight: "bold" }}>
            {" " + skillName}
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button sx={{ color: "rgb(60, 61, 61)" }} onClick={onCancel}>
          Abbrechen
        </Button>
        <Button
          sx={{ color: "rgb(157, 63, 37)" }}
          onClick={onConfirm}
          autoFocus
        >
          Ja, Löschen
        </Button>
      </DialogActions>
    </Dialog>
  );
}
