import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";

export default function AddSkillDialog({ open, onConfirm, onCancel }) {
  const [skillName, setSkillName] = useState("");
  const [skillVersion, setSkillVersion] = useState("");

  const validData = skillName.trim() !== "" && skillVersion.trim() !== "";

  return (
    <Dialog open={open} onClose={onCancel}>
      <DialogTitle id="Skill Addition">Hinzufügen einer Kompetenz</DialogTitle>
      <DialogContent>
        <DialogContentText id="Add specific skill">
          Geben Sie eine Kompetenz und eine Verion an.
        </DialogContentText>
      </DialogContent>
      <TextField
        autoFocus
        required
        margin="dense"
        id="name"
        name="addSkill"
        label="Add Skill"
        fullWidth
        variant="standard"
        onChange={(e) => setSkillName(e.target.value)}
      />
      <TextField
        autoFocus
        required
        margin="dense"
        id="name"
        name="addLevel"
        label="Add Level"
        fullWidth
        variant="standard"
        onChange={(e) => setSkillVersion(e.target.value)}
      />
      <DialogActions>
        <Button sx={{ color: "rgb(60, 61, 61)" }} onClick={onCancel}>
          Abbrechen
        </Button>
        <Button
          sx={{
            "&:hover": { backgroundColor: "#78b79a" },
            color: "rgb(60, 61, 61)",
            backgroundColor: "#aacbb7e5",
          }}
          onClick={() =>
            onConfirm({ knowledge: skillName, level: skillVersion })
          }
          autoFocus
          variant="contained"
          disabled={!validData}
        >
          Hinzufügen
        </Button>
      </DialogActions>
    </Dialog>
  );
}
